import requestService from '../requestService';

const prefix = '/documents';

export default {
  async getCategoriesList(uuid) {
    const response = await requestService.get(`${prefix}/categories/list?uuid_parent=` + uuid);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getAllCategories() {
    const response = await requestService.get(`${prefix}/categories/list`);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getDocumentsList(uuid) {
    const response = await requestService.get(`${prefix}/list?uuid_category=` + uuid);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async getOneDocument(uuid) {
    const response = await requestService.get(`${prefix}/list?uuid=` + uuid);
    const result = response.data;
    if (!result.object) {
      return [];
    }
    return response?.data?.object;
  },
  async createCategory(payload) {
    const response = await requestService.post(`${prefix}/categories/add`, payload);
    return response?.data?.object;
  },
  async createDocument(payload) {
    const response = await requestService.post(`${prefix}/add`, payload);
    return response?.data?.object;
  },
  async updateCategory(payload) {
    const response = await requestService.post(`${prefix}/categories/update`, payload);
    return response?.data?.object;
  },
  async updateDocument(payload) {
    const response = await requestService.post(`${prefix}/update`, payload);
    return response?.data?.object;
  },
  async deleteDocument(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async deleteCategory(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/categories/delete/${uuid}`);
  },
};